@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: bold;
  font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 440px) {
  #root {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div[class*="MuiGrid-item-"]
  > div[class*="MuiFormControl-root-"]
  > div[class*="MuiPaper-root-"],
div[class*="MuiGrid-item-"]
  > div[class*="MuiBox-root-"]
  > div[class*="MuiPaper-root-"] {
  box-shadow: none;
}

div[class*="MuiGrid-item-"] > div[class*="MuiButton-root-"] {
  border: "1px solid #ccc";
}
div[class*="MuiGrid-item-"]
  > div[class*="MuiFormControl-root-"]
  > fieldset[class*="MuiFormControl-root-"] {
  display: flow-root;
}
div[class*="MuiGrid-item-"]
  > div[class*="MuiFormControl-root-"]
  > fieldset[class*="MuiFormControl-root-"]
  > div {
  display: inline-block;
}
div[class*="MuiGrid-item-"]
  > div[class*="MuiFormControl-root-"]
  > label[class*="MuiFormControlLabel-root-"] {
  display: inline-block;
}
.primary-btn {
  display: block;
  padding: 6px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.rjsf > div[class*="MuiPaper-root-"] {
  display: none;
}
.MuiDataGrid-columnHeaderTitleContainer div {
  text-align: center;
  line-height: 20px;
}
.MuiDataGrid-columnHeaderTitleContainer div span {
  font-weight: bold;
  display: block;
  font-size: 11px;
}
.CookieConsent {
  z-index: 1202 !important;
}
